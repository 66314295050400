import "./ModelViewer.scss";
import "@google/model-viewer";

import { useEffect, useCallback, useState, useRef } from "react";
import { disableBodyScroll } from "body-scroll-lock";
import { useCurrentVariant } from "../../contexts/useCurrentVariant";
import { getCurrentModel } from "../ModelSelector/ModelSelector.js";
import { useCurrentProduct } from "../../contexts/useCurrentProduct";
import { useConfig } from "../../contexts/useConfig";
import { useViewerMode } from "../../contexts/useViewerMode";
import { getVariantUrl } from "../../hooks/getVariantUrl.js";
import { useLocalSessionSettings } from "../../contexts/useLocalSessionSettings";
import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader";
import ReactGA from "react-ga4";


import HandoffModal from "../Modals/Handoff/HandoffModal";
import Hotspot from "../Hotspot/Hotspot";
import PosterImage from "./PosterImage/PosterImage";
import { getPropertyOrDefault } from "../../helpers/helpers";
import { useHotspotModal } from "../../contexts/useHotspotModal";
import { Dimensions } from "./Dimensions/Dimensions.tsx";

function ModelViewer({ children, toggleUI }) {
   const [currentVariant] = useCurrentVariant();
   const [currentProduct] = useCurrentProduct();
   const [config] = useConfig();
   const [viewerMode, , setViewerMode] = useViewerMode();
   const [localSessionSettings,] = useLocalSessionSettings();
   const { hotspotModalVisible } = useHotspotModal();

   const [isMobile,] = useState(localSessionSettings.isMobile);

   const [modelViewerRendered, setModelViewerRendered] = useState(false);
   const posterImageSrc = getVariantUrl(currentProduct.uuid, currentVariant["thumbnail"]);
   const showHotspots = viewerMode === "ar" || viewerMode === "explore";
   const androidAndWebModelFormat = currentVariant.glb ? currentVariant.glb : currentVariant.gltf;
   const iosModelFormat = currentVariant.usdz;
   const androidAndWebModelSrc = getVariantUrl(currentProduct.uuid, androidAndWebModelFormat);
   const iosModelSrc = getVariantUrl(currentProduct.uuid, iosModelFormat);
   const enableWebXr = getPropertyOrDefault(config["enableWebXr"], false)
   const xrEnvironment = getPropertyOrDefault(config["xrEnvironment"], false)
   const showInteractionPrompt = getPropertyOrDefault(config["showInteractionPrompt"], true)
   const interactionPromptStyle = getPropertyOrDefault(config["interactionPromptStyle"], true)
   const hotspotOptions = getPropertyOrDefault(currentVariant.hotspot_options, null);
   const toneMapping = getPropertyOrDefault(config["toneMapping"], "auto");
   const [arStatus, setArStatus] = useState("");
   
   const modelViewerRef = useRef(null);

   useEffect(() => {
      window.addEventListener("message", function(event) {
         //console.log("event is trusted:", event.isTrusted, event)
         if (event.data.action === 'startWebXrSession') {
            //console.log("Trying to start WebXR session...")
            // modelViewerRef.current.cameraOrbit = "0deg 75deg 105%";
            // modelViewerRef.current.cameraTarget = "auto auto auto";
            // modelViewerRef.current.resetTurntableRotation();
            modelViewerRef.current.activateAR();
            }
      })

   }, [])

   //  useEffect(() => {
   //     if (modelViewerRef.current) {
   //        modelViewerRef.current.addEventListener("ar-status", (event) => {
   //           if (event.detail.status === "object-placed") {
   //             modelViewerRef.current.currentTime = 0.0;
   //             modelViewerRef.current.play();
   //          }
   //        });
   //     }
   //  }, []);

   useEffect(() => {
      if (currentProduct?.name) {
         ReactGA.event({
            category: "Model Selected",
            action: `Product Loaded`,
            label: currentProduct.name,
         });
      }
   }, [currentProduct]);

   useEffect(() => {
      if (currentVariant?.variant_name) {
         ReactGA.event({
            category: "Model Selected",
            action: `Variant Loaded`,
            label:
               currentProduct.name + "|" + currentVariant["variant_name"] ??
               currentVariant["variant-name"],
         });
      }

      if (viewerMode === "explore" && !currentVariant.hotspots) {
         setViewerMode("view");
      }
   }, [currentProduct, currentVariant, viewerMode, setViewerMode]);

   useEffect(() => {
        if (modelViewerRef.current) {
        const observer = new MutationObserver((mutationsList) => {
          for (const mutation of mutationsList) {
            if (mutation.attributeName === 'ar-status') {
              setArStatus(modelViewerRef.current.getAttribute('ar-status'));
              setCurrentHotspot(null);
            }
          }
        });
  
        observer.observe(modelViewerRef.current, { attributes: true });
  
        return () => {
          observer.disconnect();
        };
      }
    }, []);
   
   const [currentHotspot, setCurrentHotspot] = useState(null);
   const [hotspotsVisible, setHotspotsVisible] = useState(true);

   const [animationData, setAnimationData] = useState({
      name: getPropertyOrDefault(config["default-animation"], null),
      speed: 1,
      options: {
         repetitions: Infinity,
         pingpong: false,
      },
   });
   let mode = useRef("view");
   let autoRotating = useRef(false);
   let intervalID = useRef([]);
   let autoRotate = getPropertyOrDefault(config["auto-rotate"], true);
   const autoRotateSpeed = getPropertyOrDefault(config["auto-rotate-speed"], "100%");
   // const disableAutoRotateOnClick = getPropertyOrDefault(config["disable-auto-rotate-on-click"], false);
   const disablePan = getPropertyOrDefault(config["disable-pan"], false);
   //const [clickPosition, setClickPosition] = useState({x: 0, y: 0})
   const autoplayAnimations = getPropertyOrDefault(config["autoplayAnimations"], false);

   if (modelViewerRef && modelViewerRef.current) {
      window.modelViewer = modelViewerRef.current;
      console.log("scene", modelViewerRef.current.scene)
   modelViewerRef.current.scene = modelViewerRef.current[Object.getOwnPropertySymbols(modelViewerRef.current).find(
      x => x.description === "scene"
   )];


}

   // const [modelLoaded, setModelLoaded] = useState(false);

   const cameraMovePlaying = useRef(false);
   const rig = useRef(null);
   const rigTarget = useRef(null);
   const orbit = useRef(null);

   const timer = ms => new Promise(res => setTimeout(res, ms))
   const Rotate = useCallback(RecurRotate, [RecurRotate, autoRotating, autoRotateSpeed]);
   async function RecurRotate() {
      if (autoRotating.current) {
         let current = modelViewerRef.current.getCameraOrbit();
         current.theta -= autoRotateSpeed.split("%")[0] * 0.0001;
         modelViewerRef.current.cameraOrbit = current.toString();
         await timer(100);
         Rotate();
      }
   }

   const Transform = class {
      constructor(o) {
         this.position = new THREE.Vector3();
         this.rotation = new THREE.Quaternion();
         o.getWorldPosition(this.position);
         o.getWorldQuaternion(this.rotation);
      }
   };

   function IsSamePosition(p1, p2, decimalPlaces = 2) {
      if (p1.z.toFixed(decimalPlaces) !== p2.z.toFixed(decimalPlaces)) return false;
      if (p1.x.toFixed(decimalPlaces) !== p2.x.toFixed(decimalPlaces)) return false;
      if (p1.y.toFixed(decimalPlaces) !== p2.y.toFixed(decimalPlaces)) return false;
      return true;
   }

   const finished = () => {
      //Event raised once animation has finished playing
      if (cameraMovePlaying.current) {
         cameraMovePlaying.current = false;
         //If camera matrix has been defined
         //we can assume the camera is off doing a sequence and needs to be reset
         const mv = modelViewerRef.current;
         // mv.setAttribute("rotation-per-second", { autoRotateSpeed });

         // Reset controls
         mv.setAttribute("camera-controls", true)
         if (!disablePan)
            mv.removeAttribute("disable-pan");
         setHotspotsVisible(true);
         mv.interpolationDecay = 50;
         toggleUI(true);
      }
      else {
         setHotspotsVisible(true);
         toggleUI(true);
      }
   }

   const StartAutoRotating = useCallback(() => {
      if (autoRotate && mode.current !== "explore") {
         let id = setTimeout(() => {
            if (autoRotating.current === false) {
               autoRotating.current = true;
               Rotate();
            }
         }, 3000);
         console.log(id);
         intervalID.current.push(id);
      }
   }, [autoRotating, autoRotate, Rotate]);

   const StopAutoRotating = useCallback(() => {
      while (intervalID.current.length > 0) {
         let id = intervalID.current.pop();
         clearTimeout(id);
      }
      autoRotating.current = false;
   }, [autoRotating]);

   useEffect(() => {
      //On change mode
      mode.current = viewerMode;
      if (autoRotate && mode.current !== "explore") {
         StartAutoRotating();
      }
      else {
         StopAutoRotating();
      }
   }, [autoRotate, viewerMode, StartAutoRotating, StopAutoRotating]);

   // const GetSceneCameras = (type, child, matches) => {
   //    if (child.camera) {
   //       matches.push(child.camera);
   //    }
   //    child.children.forEach(c => GetSceneCameras(type, c, matches));
   // }

   const GetChildByName = (child, childName, matches) => {
      if (child.name !== "" && child.name === childName) {
         matches.push(child);
      }
      child.children.forEach(c => GetChildByName(c, childName, matches));
   }

   function Vector3ToString(v) {
      return (v.x + "m " + v.y + "m " + v.z + "m");
   }

   function WaitForLerp(camera, targetPos, OnLerpEnd) {
      setTimeout(
         function () {
            let t1 = new Transform(camera);
            if (!IsSamePosition(t1.position, targetPos, 0)) {
               WaitForLerp(camera, targetPos, OnLerpEnd);
            }
            else {
               OnLerpEnd(camera);
            }
         }, 100
      );
   }

   function OnCameraPrepDone() {
      cameraMovePlaying.current = true;
      WhileCameraMoving();
      PlayInitialAnimation();
   }

   function SetOrbitCameraPosition() {
      //TODO: Always reset the FOV before lerping
      let r = new Transform(rig.current).position;
      let camTargetPosition = rigTarget.current.position;
      modelViewerRef.current.cameraTarget = Vector3ToString(camTargetPosition);
      let currentOrbit = modelViewerRef.current.getCameraOrbit();
      let x = r.y - camTargetPosition.y;
      let y = r.x - camTargetPosition.x;
      let tilt = Math.atan2(y, x);
      var orbit = Math.atan2(r.y, camTargetPosition.z);
      currentOrbit.phi = tilt;
      currentOrbit.theta = orbit;
      let radius = r.distanceTo(camTargetPosition);
      currentOrbit.radius = radius;
      modelViewerRef.current.cameraOrbit = currentOrbit.toString();
   }

   function WhileCameraMoving() {
      setTimeout(
         function () {
            if (cameraMovePlaying.current) {
               //While the rig thing moves, align the camera with it
               SetOrbitCameraPosition();
               WhileCameraMoving();
            }
         }, 10
      );
   }

   function PrepareCameraMove(originalOrbit, transform, rig, camera, camTarget) {
      //Slow down the interpolation for the animation section
      let duration = 500;
      modelViewerRef.current.interpolationDecay = duration;

      //Set camera target
      let camTargetPos = camTarget.position;
      modelViewerRef.current.cameraTarget = Vector3ToString(camTargetPos);

      //Match the direction and position of the camera
      let currentOrbit = modelViewerRef.current.getCameraOrbit();
      let x = rig.position.y - camTarget.position.y;
      let y = rig.position.x - camTarget.position.x;
      let tilt = Math.atan2(y, x);
      currentOrbit.phi = tilt;
      currentOrbit.theta = originalOrbit + modelViewerRef.current.turntableRotation;
      currentOrbit.radius = transform.position.distanceTo(camTargetPos);
      modelViewerRef.current.cameraOrbit = currentOrbit.toString();
      WaitForLerp(camera, new Transform(rig).position, OnCameraPrepDone);
   }

   function PlayInitialAnimation() {
      var currentModel = getCurrentModel();
      if (currentModel != null) {
         if (currentModel.initialAnimation != null) {
            if (currentModel.initialAnimation.options.isolate) {
               setCurrentHotspot(null);
               setHotspotsVisible(false);
            }
            setAnimationData(currentModel.initialAnimation);
         }
         else {
            //If no animation defined, set to default
            setAnimationData({
               name: getPropertyOrDefault(config["default-animation"], null),
               speed: 1,
               options: {
                  repetitions: Infinity,
                  pingpong: false,
               },
            });
         }
      }
   }

   const loadSecondModel = () => {
      const threeScene = modelViewerRef.current.scene;

      if (!threeScene) {
         console.error("Three.js scene not found.");
         return;
      }

      console.log("Three.js scene found!");

      const loader = new GLTFLoader();
      const dracoLoader = new DRACOLoader();
      dracoLoader.setDecoderPath("https://www.gstatic.com/draco/v1/decoders/");
      loader.setDRACOLoader(dracoLoader);

      const modelGroup = new THREE.Group();
      threeScene.add(modelGroup);

      loader.load(
         config["second-model-link"],
         (gltf) => {
            const secondModel = gltf.scene;
            secondModel.position.set(1.7, 0, 0);

            threeScene.children[0].add(secondModel);
            console.log("Second model added successfully!");
         },
         undefined,
         (error) => {
            console.error("Error loading second model:", error);
         }
      );
   }

   const onModelLoaded = () => {
      getCustomTexturesFromConfig();

      if(config["second-model"]){
         loadSecondModel();
      }
      
      if (autoplayAnimations) {
         playCameraAnimation();
      }
   }
   

   const playCameraAnimation = () => {
      if (modelViewerRef.current != null) {
         let rigs = [];
         let rigTargets = [];
         const mv = modelViewerRef.current;
         modelViewerRef.current.scene.children.forEach(c => GetChildByName(c, "rig", rigs));
         modelViewerRef.current.scene.children.forEach(c => GetChildByName(c, "rig-target", rigTargets));
         //If there is a camera defined prep the camera
         if (rigs.length === 1 && rigTargets.length === 1) {
            rig.current = rigs[0];
            rigTarget.current = rigTargets[0];

            //Here we sample the orbit of the rig and rig-target
            //ahead of the turntable roation
            let o = Math.atan2(rigs[0].position.y, rigTargets[0].position.z);
            orbit.current = o;

            //Disable the controls
            mv.removeAttribute("camera-controls");
            StopAutoRotating();
            // mv.removeAttribute("auto-rotate");
            mv.setAttribute("disable-pan", true);
            mv.setAttribute("rotation-per-second", "0%");

            //Ensure animation is ready to play
            modelViewerRef.current.timeScale = 0.0;
            modelViewerRef.current.play();
            toggleUI(false);
            PrepareCameraMove(o, new Transform(rigs[0]), rigs[0], modelViewerRef.current.scene.camera, rigTargets[0]);
         }
         else if (rigTargets.length === 1) {
            //If there is a camera target defined
            // modelViewerRef.current.interpolationDecay = 500;
            let p = rigTargets[0].position;
            modelViewerRef.current.cameraTarget = Vector3ToString(p);
            ResetCameraDefault(false);
            PlayInitialAnimation();
         }
         else if (rigs.length > 1 || rigTargets.length > 1) {
            console.error("Too many cameras or camera targets defined");
         }
         else {
            ResetCameraDefault(true);
            PlayInitialAnimation();
         }
      }
   
   }

   const getCustomTexturesFromConfig = () => {
      console.log("yeeehhaw")
      if (currentVariant.customMaterials) {
         console.log(currentVariant.customMaterials)
         console.log("yeee 2")
         for (let customMaterial of currentVariant.customMaterials) {
            console.log("yeee 3")
            console.log(customMaterial)
            for (let texture of customMaterial.textures) {
               console.log("yeee 4")
               console.log(texture)
               loadCustomTexture(customMaterial.name, texture.name, customMaterial.baseColor, getVariantUrl(currentProduct.uuid, texture.filename), texture.strength)
            }
         }
      }
   }


   function ResetCameraDefault(resetCamTarget = true) {
      if (resetCamTarget) {
         setDefaultCameraTarget();
      }

      setDefaultFieldOfView();
      setMinFieldOfView();
      setMaxFieldOfView();

      setMinCameraOrbit();
      setMaxCameraOrbit();
      setDefaultCameraOrbit();
      setDefaultCameraTarget();
   }

   
    function loadCustomTexture(materialName, mapName, baseColor, textureUrl, strength) {
       const textureLoader = new THREE.TextureLoader();

       textureLoader.load(
          textureUrl,
          (texture) => {
             texture.flipY = false;
             texture.anisotropy = 4;
             texture.colorSpace = (mapName.toUpperCase() === "ALBEDODIFFUSE" || mapName.toUpperCase() === "EMISSIVE") ? THREE.SRGBColorSpace : THREE.NoColorSpace;
             texture.format = THREE.RGBAFormat;
             //  console.log('Texture loaded:', texture);

             modelViewerRef.current.scene.traverse((node) => {
                if (node.isMesh) {
                   const material = node.material;
                   if (material && material.name.toUpperCase().includes(materialName.toUpperCase())) {
                      //  console.log(`Material found: ${material.name}, Type: ${material.type}`);
                      if (material.type === "MeshStandardMaterial") {
                        switch(mapName) {
                           case "albedoDiffuse": {
                              material.color = new THREE.Color( baseColor ?? 0xffffff )
                              material.map = texture;
                              break;
                           }
                           case "alpha": {
                              material.alphaMap = texture;
                              break;
                           }
                           case "ao": {
                              material.aoMap = texture;
                              material.aoMapIntensity = strength;
                              break;
                           }
                           case "bump": {
                              material.bumpMap = texture;
                              material.bumpScale = strength;
                              break;
                           }
                           case "displacement": {
                              material.displacementMap = texture;
                              material.displacementScale = strength;
                              break;
                           }
                           case "emissive": {
                              material.emissive = new THREE.Color( 0xffffff )
                              material.emissiveMap = texture;
                              material.emissiveIntensity = strength;
                              break;
                           }
                           case "metalness": {
                              material.metalnessMap = texture;
                              material.metalness = strength;
                              break;
                           }
                           case "normal": {
                              material.normalMap = texture;
                              material.normalScale.set(strength, strength);
                              break;
                           }
                           case "roughness": {
                              material.roughnessMap = texture;
                              material.roughness = strength;
                              break;
                           }
                           default: {
                              material.map = texture;
                              break;
                           }
                        }
                        material.needsUpdate = true;
                      }
                   }
                }
             });
          },
          undefined,
          (err) => {
             console.error("Error loading texture:", err);
          }
       );
    }


   useEffect(() => {
      if (modelViewerRef.current) {
         disableBodyScroll(modelViewerRef);
         setModelViewerRendered(true);
         modelViewerRef.current.modelCacheSize = 2;
         modelViewerRef.current.minimumRenderScale = 0.25;
         const clickHandler = () => {
            modelViewerRef.current.dismissPoster();
            modelViewerRef.current.removeEventListener("click", clickHandler);
         };
         const mouseDownHandler = () => {
            StopAutoRotating();
         };
         const mouseUpHandler = () => {
            StartAutoRotating();
         };
         
         modelViewerRef.current.addEventListener("click", clickHandler);
         modelViewerRef.current.addEventListener("mousedown", mouseDownHandler);
         modelViewerRef.current.addEventListener("touchstart", mouseDownHandler);
         modelViewerRef.current.addEventListener("mouseup", mouseUpHandler);
         modelViewerRef.current.addEventListener("touchend", mouseUpHandler);
         modelViewerRef.current.addEventListener('finished', finished);
         modelViewerRef.current.addEventListener('load', onModelLoaded);
         setBackgroundImage();
         setProgressBarStyle();

         
         setMinCameraOrbit();
         setMaxCameraOrbit();
         setDefaultCameraOrbit();
         setDefaultCameraTarget();

         setDefaultFieldOfView();
         setMinFieldOfView();
         setMaxFieldOfView();

         setCurrentHotspot(getMinHotspot());
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   // useEffect(() => {
   //    if (modelViewerRef.current) {
   //       const mouseDownHandler = (e) => {
   //          setClickPosition({ x: e.clientX, y: e.clientY });
   //       };

   //       modelViewerRef.current.addEventListener("mousedown", mouseDownHandler);
   //    }
   // }, []);

   // useEffect(() => {
   //    const mouseUpHandler = (e) => {
   //       if (movedLessThanDistance(e.clientX, e.clientY, clickPosition.x, clickPosition.y, 5)) {
   //          const useMeshId = false;

   //          if (useMeshId) {
   //             const stuff = modelViewerRef.current.surfaceFromPoint(e.clientX, e.clientY);
   //             console.log(stuff);
   //             if (stuff) {
   //                const materialIndex = Number.parseInt(stuff.split(" ")[0]);

   //                switch (materialIndex) {
   //                   case 0:
   //                      console.log("Setting animation to Running");
   //                      setAnimationName("Running");
   //                      break;
   //                   default:
   //                      console.log("Removing animation");
   //                      setAnimationName(null);
   //                      break;
   //                }
   //             }
   //          } else {
   //             const material = modelViewerRef.current.materialFromPoint(e.clientX, e.clientY);
   //             if (material != null) {
   //                console.log(material);
   //                console.log(material.name);
   //                switch (material.name) {
   //                   case "S3PrinterGlass.001":
   //                      console.log("Setting animation to Running");
   //                      setAnimationName("Running");
   //                      break;
   //                   default:
   //                      console.log("Removing animation");
   //                      setAnimationName(null);
   //                      break;
   //                }
   //             }
   //          }
   //       }
   //    };

   //    const movedLessThanDistance = (startX, startY, endX, endY, distanceinPx) => {
   //       const absX = Math.abs(endX - startX);
   //       const absY = Math.abs(endY - startY);
   //       const sqrDist = Math.pow(absX, 2) + Math.pow(absY, 2);
   //       return sqrDist <= Math.pow(distanceinPx, 2);
   //    };
   //    modelViewerRef.current.addEventListener("mouseup", mouseUpHandler);
   // }, [clickPosition, setAnimationName]);

   const getMinHotspot = () => {
      if (currentVariant.hotspots) {
         const hotspotIds = currentVariant.hotspots.map(hotspot => hotspot.id);
         let min = Infinity;
         for (let i of hotspotIds) {
            if (i < min) {
               min = i;
            }
         }
         return min;
      } else {
         return 0;
      }
   }

   const setBackgroundImage = () => {
      if (config["background-image"]) {
         document.documentElement.style.setProperty("--background-image", `url("${config["background-image"]}")`)

         if (config["background-image-padding"]) {
            document.documentElement.style.setProperty("--background-image-padding", config["background-image-padding"])
         }
         if (isMobile) {
            if (config["background-image-padding-mobile"]) {
               document.documentElement.style.setProperty("--background-image-padding", config["background-image-padding-mobile"])
            }
         }
         if (config["background-image-fit"]) {
            document.documentElement.style.setProperty("--background-image-fit", config["background-image-fit"]);

            if (config["background-image-repeat"]) {
               document.documentElement.style.setProperty("--background-image-repeat", config["background-image-repeat"]);
            }
            if (config["background-image-position"]) {
               document.documentElement.style.setProperty("--background-image-position", config["background-image-position"]);
            }
         }
         else {
            document.documentElement.style.setProperty("--background-image-fit", "cover");
         }
      }
   }

   const setDefaultCameraTarget = () => {
      console.log("setting default camera target")
      const defaultCameraTarget = getPropertyOrDefault(config.defaultCameraTarget, null);
      if (isMobile) {
         const defaultCameraTargetMobile = getPropertyOrDefault(config.defaultCameraTargetMobile, defaultCameraTarget);
         if (defaultCameraTargetMobile) {
            modelViewerRef.current.cameraTarget = defaultCameraTargetMobile;
         }
         else modelViewerRef.current.cameraTarget = "auto auto auto";
      } else {
         if (defaultCameraTarget) {
            console.log("setting default camera target 2")
            modelViewerRef.current.cameraTarget = defaultCameraTarget;
         }
         else modelViewerRef.current.cameraTarget = "auto auto auto";
      }
   }

   const setDefaultCameraOrbit = () => {
      if (config["default-camera-orbit"]) {
         modelViewerRef.current.cameraOrbit = config["default-camera-orbit"];
      }
      
      if (config["second-model"]) {
         const [azimuth, elevation, distance] = modelViewerRef.current.cameraOrbit.split(" ");
         const newDistance = `${parseFloat(distance) * 1.5}%`;
         modelViewerRef.current.cameraOrbit = `${azimuth} ${elevation} ${newDistance}`;
      }
   }
   const setMinCameraOrbit = () => {
      if (config["min-camera-orbit"]) {
         modelViewerRef.current.minCameraOrbit = config["min-camera-orbit"];
      }
   }
   const setMaxCameraOrbit = () => {
      if (config["max-camera-orbit"]) {
         modelViewerRef.current.maxCameraOrbit = config["max-camera-orbit"];
      }

      if (config["second-model"]) {
         const [, , distance] = modelViewerRef.current.cameraOrbit.split(" ");
         const [azimuth, elevation] = modelViewerRef.current.maxCameraOrbit.split(" ");
         const newDistance = `${parseFloat(distance) * 1.5}%`;
         modelViewerRef.current.maxCameraOrbit = `${azimuth} ${elevation ? elevation : "auto"} ${newDistance}`;
      }
   }

   const setDefaultFieldOfView = () => {
      if (config["default-fieldOfView"]) {
         modelViewerRef.current.fieldOfView = config["default-fieldOfView"];
      }
   }
   const setMinFieldOfView = () => {
      if (config["min-fieldOfView"]) {
         modelViewerRef.current.minFieldOfView = config["min-fieldOfView"];
      }
   }
   const setMaxFieldOfView = () => {
      if (config["max-fieldOfView"]) {
         modelViewerRef.current.maxFieldOfView = config["max-fieldOfView"];
      }
   }

   const setProgressBarStyle = () => {
      if (config["progress-bar-color"]) {
         document.documentElement.style.setProperty(
            "--progress-bar-color",
            config["progress-bar-color"]
         );
      }

      if (config["progress-bar-height"]) {
         document.documentElement.style.setProperty(
            "--progress-bar-height",
            config["progress-bar-height"]
         );
      }
   };

   useEffect(() => {
      //On animation data changed
      if (modelViewerRef.current) {
         modelViewerRef.current.timeScale = animationData.speed;
         modelViewerRef.current.pause();
         if (animationData.options.isolate) {
            setHotspotsVisible(false);
         }
         setTimeout(() => {
            modelViewerRef.current.play(animationData.options)
         }, animationData.options.delay);
      }
   }, [animationData])

   const handleAnimationDataChanged = (animationData) => {
      setAnimationData(animationData);
   }

   console.log(localSessionSettings.showDimensions)
   return (
      <div
         className="reydar-model-viewer"
         allowFullScreen
         mozallowfullscreen="true"
         webkitallowfullscreen="true"
      >
         <div className="background-image"></div>
         <model-viewer
            ref={modelViewerRef}
            className="model-viewer"
            alt="Reydar Model Viewer"
            autoplay={autoplayAnimations ? true : null}
            animation-name={animationData.name}
            src={androidAndWebModelSrc}
            ios-src={iosModelSrc}
            ar
            ar-modes={enableWebXr ? "webxr scene-viewer quick-look " : "scene-viewer quick-look"}
            ar-scale={config["ar-scale"] ?? "fixed"}
            ar-status={arStatus}
            xr-environment={xrEnvironment ? true : null}
            skybox-image={config["skybox-environment"]}
            environment-image={config["background-environment"]}
            tone-mapping={toneMapping}
            ar-placement={config["arPlacement"] ?? "floor"}
            loading={config["show-poster"] ? "lazy" : "auto"}
            reveal={config["show-poster"] ? "interaction" : "auto"}
            exposure={config["exposure"] ? config["exposure"] : 1}
            shadow-intensity={config["shadow-intensity"] ?? "1"}
            shadow-softness={config["shadow-softness"] ?? "1"}
            camera-controls
            disable-pan={disablePan ? true : null}
            // auto-rotate={(localSessionSettings.autoRotateAvailable && autoRotate && !pauseRotation) ? "" : null}
            rotation-per-second={autoRotateSpeed}
            interaction-prompt={showInteractionPrompt ? "auto" : "none"}
            interaction-prompt-style={interactionPromptStyle}
         >
            <button slot="ar-button" style={{ display: "none" }}></button>
            {modelViewerRendered && <HandoffModal />}
            {!hotspotModalVisible &&
            showHotspots &&
               currentVariant.hotspots &&
               currentVariant.hotspots.map((hotspotData) =>
                  <Hotspot
                     key={hotspotData.id}
                     hotspotData={hotspotData}
                     setCurrentHotspot={setCurrentHotspot}
                     currentHotspot={currentHotspot}
                     hotspotOptions={hotspotOptions}
                     handleAnimationDataChanged={handleAnimationDataChanged}
                     hotspotsVisible={hotspotsVisible}
                     arStatus={arStatus}
                     >
                  </Hotspot>
               )}
            {children}
            {config["show-poster"] && <PosterImage posterImageSrc={posterImageSrc} />}
            {<Dimensions modelViewerRef={modelViewerRef} active={localSessionSettings.showDimensions}/>}
            </model-viewer>
      </div>
   );
}

export default ModelViewer;
